
    /**
     * First we will load all of this project's JavaScript dependencies which
     * includes Vue and other libraries. It is a great starting point when
     * building robust, powerful web applications using Vue and Laravel.
     */

    //require('./bootstrap');

    //window.Vue = require('vue');

    /**
     * Next, we will create a fresh Vue application instance and attach it to
     * the page. Then, you may begin adding components to this application
     * or customize the JavaScript scaffolding to fit your unique needs.


    Vue.component('example', require('./components/Example.vue'));

    const app = new Vue({
        el: '#app'
    }); */

// SCRIPTS FRONT END //

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE");
    var nbAudioClick = 0;
    var nbVideoClick = 0;

    $('document').ready(setup);

    function setup() {
        $('.modaltry-content .btn-try').click(closeModalTry);
        if($('.exercice').length != 0) adjustBreadcrump();
        if (msie > -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) { minHeightContent(); $(window).resize(minHeightContent); }
        $('.menu .deconnect, .menu .message').click(goesToURL);
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        if($('.audio').length != 0 || $('.imageson').length != 0 || $('.audioexpli').length != 0) { audioPlayer(); }
        if($('.video').length != 0) { $('.video').click(startVideo); }

        if($('.audio').length != 0) {
            detectStartAudio();
            //$('.btn-next-big').click(verifAudioClick);
        }

        if($('video').length != 0) {
            detectStartVideo();
            $('.btn-next-big').click(verifVideoClick);
            $('.video').click('startVideo');
        }

        if($('.messagesArchive').length != 0) scrollMessagerieToBottom();

        changeAccessAccount();

    }

    // Liste déroulante Access Profil
    $(".list-access-account").on("click", ".init", function() {
        $(this).closest("ul").children('li:not(.init)').toggle();
    });

    //var allOptions = $(".list-access-account").children('li:not(.init)');
    $(".list-access-account").on("click", "li:not(.init)", function() {
        $(this).closest(".list-access-account").children('li:not(.init)').removeClass('selected');
        $(this).addClass('selected');
        $(this).closest(".list-access-account").children('.init').html($(this).html());
        $(this).closest(".list-access-account").children('li:not(.init)').toggle();
    });

    function changeAccessAccount() {
        $('body').on('click', '.list-access-account .access', function (e) {
            e.preventDefault();

            $this = $(this);
            $.ajax({
                url: '/ajax/utilisateur/access',
                type: 'POST',
                dataType: 'json',
                data: {'access': $this.data('access'),'customer' : $this.data('customer')},
                success: function (data) {
                    if(data.redirect) {
                        document.location.href = data.redirect
                    }
                    if(data.errors) {
                        alert(data.errors)
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                }
            });
        });
    }

    // Fonction qui permet de se déconnecter sur mobile ou tablette
    function goesToURL() {
        var newURL = $(this).children('a').attr('href');
        $(location).attr('href', newURL);
    }

    // Fonction qui permet de régler la hauteur minimale si le contenu ne dépasse pas la taille de la fenêtre sur IE
    function minHeightContent() {
        $('.footer').removeAttr('style');
        $('body').removeAttr('style');
        $('.content').removeAttr('style');

        if($('.header').length == 0) {
            if($('.course').length == 0) {
                if(($('.content').innerHeight() + $('.navigation').innerHeight()) < $(window).height()) {
                    if($('.navigation').length != 0) {
                        var newMinHeight = $(window).height() - ($('.navigation').height() + $('.footer').height());
                        $('body').css({
                            'max-height': $(window).height() + 'px',
                            'overflow' : 'hidden'
                        });

                        $('.footer').css('position', 'absolute');
                        $('.footer').css('bottom', '0px');
                    }else {
                        var newMinHeight = $(window).height();
                    }
                    $('.content').css('min-height', newMinHeight + 'px');
                }
            }else {
                if(navigator.userAgent.match(/iPad/i)) $('.course').css('min-height', $(window).height() + 50 + 'px');
            }
        }else {
            if(($('.content').innerHeight() + $('.header').innerHeight() + $('.navigation').innerHeight() + $('.footer').innerHeight()) < $(window).height()) {
                if($('.navigation').length != 0) {
                    var newMinHeight = $(window).height() - ($('.navigation').height() + $('.header').height() + $('.footer').height());
                    $('.footer').css('position', 'absolute');
                    $('.footer').css('bottom', '0px');
                }else {
                    var newMinHeight = $(window).height();
                }
                $('.content').css('min-height', newMinHeight + 'px');
                if($('.learnerhome').length != 0) $('.content .wrapper').css('min-height', newMinHeight + 'px');
            }
        }

        if($('.training').length != 0) {
            if(($('.training').innerHeight() + $('.header').innerHeight() + $('.navigation').innerHeight() + $('.footer').innerHeight()) < $(window).height()) {
                var newMinHeight = $(window).height() - ($('.header').innerHeight() + $('.navigation').innerHeight() + $('.footer').innerHeight());
                $('.training').css('min-height', newMinHeight + 'px');
                $('.footer').css('position', 'absolute');
                $('.footer').css('bottom', '0px');
            }
        }

        if($('.course').length != 0) {
            var positionCourseModules = $('.course-modules').position();
            if(($('.course').height()) < $(window).height()) {
                if($('.navigation').length != 0) {
                    var newMinHeight = $(window).height() - ($('.navigation').height() + $('.footer').height());
                    $('.footer').css('position', 'absolute');
                    $('.footer').css('bottom', '0px');
                }
                $('.course').css('min-height', newMinHeight + 'px');
            }
        }
    }

    // Fonction qui permet de fermer la modale pour réessayer un exercice
    function closeModalTry() {
        $('.modaltry').fadeOut(500);
    }

    // Fonction qui permet d'ajuster la navigation en haut de page d'une session
    function adjustBreadcrump() {
        $('.exercice-nav .current').each(function() {
            if($(this).next().length != 0) {
                if($(this).next().attr('class').indexOf('e') != -1) $(this).addClass('hasNextElem');
            }
        });
    }

    // Fonction qui permet de gérer le lecteur audio personnalisé
    function audioPlayer() {
        // On met à jour le temps total de l'audio
        $('audio').on("canplay", function () {
            var sec = parseInt(this.duration % 60);
            var min = parseInt(this.duration / 60) % 60;
            if(sec < 10) sec = '0' + sec;
            $(this).prev().children('.audioControls-time').children('.duration').html(min + ':' + sec);
        });

        $('audio').on('play', function() {
            var sec = parseInt(this.duration % 60);
            var min = parseInt(this.duration / 60) % 60;
            if(sec < 10) sec = '0' + sec;
            $(this).prev().children('.audioControls-time').children('.duration').html(min + ':' + sec);
        });

        // On met à jour le temps de défilement de l'audio
        $('audio').bind('timeupdate',function(){
            //Get hours and minutes
            var sec = parseInt(this.currentTime % 60);
            var min = parseInt(this.currentTime / 60) % 60;
            if(sec < 10) sec = '0'+sec;
            $(this).prev().children('.audioControls-time').children('.currentTime').html(min + ':' + sec);

            var value = 0;
            if(this.currentTime > 0) value = Math.floor((100 / this.duration) * this.currentTime);
            $(this).prev().children('.audioControls-duration').children('.bar').css('width',value+'%');
        });

        // On remet à zéro la lecture du temps quand l'audio est terminé
        $('audio').on('ended', function() {
            $(this).prev().removeClass('play');
            $(this).prev().children('.audioControls-duration').children('.bar').removeAttr('style');
            $(this).prev().children('.audioControls-duration').children('.bar').css('width', '100%');
        });

        // Lecture ou Pause de l'audio
        $('.audioControls-playstop').click(function() {
            if($(this).parent().hasClass('play')) {
                $(this).parent().removeClass('play');
                $(this).parent().next().trigger('pause');
            }else {
                // On met d'abord en pause tous les sons audios
                $('audio').trigger('pause');
                $('.audioControls-playstop').parent().removeClass('play');
                $(this).parent().addClass('play');
                $(this).parent().next().trigger('play');
            }
        });

        $('.audio-elem .image, .imageson .image').click(function() {
            if($(this).next().children('.audioControls').hasClass('play') == false) {
                $(this).next().children('.audioControls').addClass('play');
                $(this).next().children('audio').trigger('play');
            }
        });

        if($('.audioexpli').length != 0) {
            setTimeout(function() { $('.audioexpli .audioControls-playstop').click(); }, 700);
        }
    }

    // Fonction pour jouer une vidéo
    function startVideo() {
        if($(this).hasClass('isPlay')) {
            $(this).children('video').trigger('pause');
            $(this).removeClass('isPlay');
        }else {
            $(this).children('video').trigger('play');
            $(this).addClass('isPlay');
        }

        $(this).children('video').on('ended', function() {
            $(this).parent().removeClass('isPlay');
            $(this).removeClass('isPlay');
        });
    }

    // Fonction qui permet de détecter quand un son a été joué
    function detectStartAudio() {
        $('audio').on('ended', function() {
            if($(this).hasClass('hasBeenPlayed') == false) {
                $(this).addClass('hasBeenPlayed');
                nbAudioClick++;
            }
        });
    }

    // Fonction qui permet de vérifier si l'utilisateur a écouté tous les sons avant de passer à l'exo suivant
    function verifAudioClick(e) {
        if($('.validcurrentstep').length == 0 && $('.errorcurrentstep').length == 0) {
            if(nbAudioClick != $('.audio .audio-elem').length) {
                e.preventDefault();
                $('.modalaudio').fadeIn(500);
            }
        }
    }

    // Fonction qui permet que l'utilisateur a regardé les vidéos des exercices avant de passer à la suite
    // Fonction qui permet de détecter quand une vidéo a été jouée
    function detectStartVideo() {
        $('video').on('play', function() {
            if($(this).hasClass('isPlaying') == false) {
                $(this).addClass('isPlaying');
            }
        });

        $('video').on('ended', function() {
            if($(this).hasClass('hasBeenPlayed') == false) {
                $(this).addClass('hasBeenPlayed');
                $(this).removeClass('isPlaying');
                nbVideoClick++;
            }
        });
    }

    function verifVideoClick(e) {
        if($('.validcurrentstep').length == 0 && $('.errorcurrentstep').length == 0) {
            if(nbVideoClick != $('video').length) {
                e.preventDefault();
                $('.modalvideo').fadeIn(500);
                $('video').trigger('pause');
            }
        }
    }

    // Fonction qui permet de calculer le temps de connexion
    function connection_time() {
        setInterval(function () {
            $.ajax({
                url: '/ajax/connexion/effective-time',
                type: 'POST',
                dataType: 'json',
                success: function (data) {

                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                }
            });
        }, 60000);

    }

    // Permet de scroller au dernier message d'une conversation dans la messagerie
    function scrollMessagerieToBottom() {
        $(".messagesArchive").scrollTop($(".messagesArchive")[0].scrollHeight);
    }
